import React, {FC, useEffect, useState} from "react";
import Iframe from "react-iframe";
import {useDispatch, useSelector} from "react-redux";

import {
    handleScoreBlueBookTest,
} from "Store/tests/actions";
import CustomModal from "../CustomModal/CustomModal";
import Spinner from "../../Spinner/Spinner";
import {makeSelectStudentActiveTest, makeSelectStudentTests} from "../../../../Store/tests/selectors";
import {act} from "react-dom/test-utils";
import {handleGetTodo} from "../../../../Store/dashboard/actions";
import Button from "../../Button/Button";

interface IProps {
  onClose: () => void;
  onDone: () => void;
  test: Test;
}

const BlueBookTestModal: FC<IProps> = ({ test, onClose, onDone }) => {
    const dispatch = useDispatch();
    const [matches, setMatches] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [math, setMath] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [reading, setReading] = useState(0);
    const [formData, setFormData] = useState({
        paste: '',
    });

    useEffect(() => {
        setTotalScore(math+reading);
    }, [math,reading]);

    useEffect(() => {
        if (test.status == 'COMPLETED')
            onClose();
    }, [test]);

    const handleMath = (event) => {
        setMath(parseInt(event.target.value));
    }
    const handleMathOut = (event) => {
        const value = Math.max(200, Math.min(800, Number(event.target.value)));
        setMath(value);
    }

    const handleReading = (event) => {
        setReading(parseInt(event.target.value));
    }

    const handleReadingOut = (event) => {
        const value = Math.max(200, Math.min(800, Number(event.target.value)));
        setReading(value);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        const pattern = /^(\d+)\t(Reading and Writing|Math)\t\n([A-D]+|[-.\d\s,/]+)\n(([A-D]+|[-.\d\s,/]+); (Correct|Omitted|Incorrect)|(Omitted))/gm;
        const data = [...value.matchAll(pattern)];
        let answers = data.map(match => {
            if (match[4] == 'Omitted') {
                return [match[1], match[2], match[3], "", match[4]]
            } else {
                return [match[1], match[2], match[3], match[5], match[6]]
            }
        });
        let i = 0;
        let subject = '';

        setMatches(answers.map(answer => {
            if (subject == '' || subject != answer[1]) {
                subject = answer[1];
                i = 0;
            }
            i++;
            return {
                question: i,
                subject: answer[1],
                correct: answer[2],
                answer: answer[3],
                status: answer[4]
            }
        }));

        setFormData({
            paste: ''
        });
    };

    const handleSubmit = (event) => {
        setDisabled(true);
        event.preventDefault();
        // You can perform any action with the form data here, like sending it to a server
        dispatch(handleScoreBlueBookTest(
            test,
            [{name: "Reading & Writing", score: reading}, {name: "Math", score: math}],
            matches
        ));
        onDone();
        setTimeout(() => setDisabled(false), 8000);
    };
  return (
    <div>
          <CustomModal
              onClose={onClose}
              header="BlueBook Test"
              disabled={disabled}
              onClick={handleSubmit}
              button="Submit"
              message=""
              customClass="blue-book-view"
          >
            <div className={"blue-book"}>
                <form onSubmit={handleSubmit}>
                    <h2>Score</h2>
                    <div className={'scores'}>
                        <div>
                            <label htmlFor="subjects">Reading and Writing</label>
                            <input type="number" value={reading} onChange={handleReading} onBlur={handleReadingOut} />
                        </div>
                        <div>
                            <label htmlFor="subjects">Math</label>
                            <input type="number" value={math} onChange={handleMath} onBlur={handleMathOut} />
                        </div>
                        <div>
                            <label htmlFor="subjects">Total Score</label>
                            <input type="number" readOnly={true} value={totalScore}/>
                        </div>
                    </div>
                    <div className={'answers'}>
                        <label htmlFor="name">Paste the answers</label>
                        <textarea
                            id="paste"
                            name="paste"
                            value={formData.paste}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <table>
                            <thead>
                            <tr>
                                <th>Question #</th>
                                <th>Subject</th>
                                <th>Correct</th>
                                <th>Answer</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {matches.map((entry) => (
                                <tr>
                                    <td>{entry.question}</td>
                                    <td>{entry.subject}</td>
                                    <td>{entry.correct}</td>
                                    <td><input readOnly={true} type="text" value={entry.answer}/></td>
                                    <td>{entry.status}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
          </CustomModal>
    </div>
  );
};

export default BlueBookTestModal;
